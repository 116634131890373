<template>
    <div>
        <div class="top">
            <div class="selector-wrapper">
                <div class="selector-label-wrapper">
                    <div class="selector-label" :class="currentLabel==0 ? 'selector-active' : ''" @click="clickLabel(0,0)">今天</div>
                    <div class="selector-label" :class="currentLabel==-6 ? 'selector-active' : ''" @click="clickLabel(-6,0)">近七天</div>
                    <div class="selector-label" :class="currentLabel=='all' ? 'selector-active' : ''" @click="clickLabel('all',0)">全部</div>
                </div>
                <div class="selector-date-wrapper">
                    <input class="selector-date-input" type="text" v-model="startTime" @click="show = true"/>
                    <div class="selector-font">至</div>
                    <input class="selector-date-input" type="text" v-model="endTime" @click="show = true"/>
                </div>
            </div>
            <van-calendar v-model="show" :min-date="minDate" :default-date="computedDefaultDate" type="range" @confirm="onConfirm" />
        </div>
        <div style="width: 100%;height: 4.5rem;"></div>

        <div class="shop" v-for="(item,index) in list" :key="index" >
            <div v-if="item.check != 0">
                <div class="shop_title">
                    {{item.name}} <span style="color: #D05A56">（核销数量：{{item.check}}）</span>
                </div>
                <div class="shop_content">
                    <div style="width: 70%">门店</div>
                    <div>核销数量</div>
                </div>
                <div v-for="(item2,index2) in item.list" :key="index2" class="shop_content">
                    <div style="width: 70%">{{item2.business_name}}</div>
                    <div>{{item2.count}}</div>
                </div>
            </div>
        </div>
        <check-float-nav></check-float-nav>
    </div>
</template>
<script>
    import { getStandardDate, formatGMT2StandardDate } from '../../utils/tools'
    import CheckFloatNav from '../../components/check/CheckFloatNav'
    import { globalConfig } from '../../utils/mixin'
    export default {
        name: 'OrderList',
        mixins: [globalConfig],
        components: { CheckFloatNav },
        data () {
            return {
                permission: '',
                list: [],
                minDate: new Date(2010, 0, 1),
                startTime: '',
                endTime: '',
                show: false,
                currentLabel: 'all'
            }
        },
        methods: {
            // 日历选择回调函数
            onConfirm (date) {
                const [start, end] = date
                this.startTime = formatGMT2StandardDate(start)
                this.endTime = formatGMT2StandardDate(end)
                this.currentLabel = 99
                this.choiceTime()
                this.show = false
                this.init()
            },
            // 点击快捷搜索标签
            clickLabel (start, end) {
                if (start == 'all') {
                    this.startTime = '2017-01-01'
                } else {
                    this.startTime = getStandardDate(start)
                }
                this.currentLabel = start
                this.endTime = getStandardDate(end)
                this.choiceTime()
                this.init()
            },
            // 选择时间
            choiceTime () {
                this.$emit('refreshTime', this.startTime, this.endTime)
            },
            // 字符串转成Date对象
            str2Date (str) {
                const arr = str.split('-')
                return new Date(arr[0], arr[1] - 1, arr[2])
            },
            init: function () {
                this.axios.post(this.apiUrl + 'checkh5/order/getCheckStoreList', {
                    startTime: this.startTime,
                    endTime: this.endTime
                }).then((response) => {
                    console.log(response)
                    const data = response.data
                    if (data.status === 1) {
                        this.list = data.list
                    }
                })
            }
        },
        created () {
            this.startTime = '2017-01-01'
            this.endTime = getStandardDate(0)
            this.init()
        },
        computed: {
            computedDefaultDate: function () {
                return [this.str2Date(this.startTime), this.str2Date(this.endTime)]
            }
        },
        mounted () {

        }
    }
</script>
<style scoped>
    .top{
        position: fixed;
        top: 0;
        width: 100%;
    }
    .selector-wrapper{
        width: 100vw;
        display: flex;
        background-color: #FFFFff;
        box-shadow:0 8px 10px -8px #999;
        padding: 0.5rem 0;
    }
    .selector-label-wrapper{
        width: 45%;
        display: flex;
        color: #999;
        font-size: 3vw;
        padding: 3vw 1vw;
        box-sizing: border-box;
    }
    .selector-label{
        background: #f8f8f8;
        padding: 0.25vw 2vw;
        border-radius: 4vw;
        margin: 0 1.2vw;
    }
    .selector-active{
        background: #FFE8D8;
        padding: 0.2vw 2vw;
        border-radius: 4vw;
        color: #ff4949;
        margin: 0 1.2vw;
    }
    .selector-date-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 1rem 0 0;
        width: 55%;
        box-sizing: border-box;
    }
    .selector-date-input{
        width: 20vw;
        height: 5vw;
        border-radius: 5vw;
        border: 1px solid #b8b8b8;
        font-size: 2.8vw;
        text-align: center;
    }
    .selector-font{
        margin: 0 1vw;
        font-size: 3.2vw;
        color: #333;
    }
    .shop{
        width: 92vw;
        margin: 5vw 4vw;
    }
    .shop_title{
        padding: 2vw 4vw;
        border: 1px solid #ececec;
        color: #333;
        font-size: 3.8vw;
    }
    .shop_content{
        display: flex;
        padding: 2vw 4vw;
        border: 1px solid #ececec;
        color: #666;
        font-size: 3.4vw;
    }
    .shop_content div{
        width: 30%;
        text-align: center;
    }
</style>
